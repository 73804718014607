@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap');
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption,
section,
div {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-family: 'Open Sans', sans-serif;
  /* font-weight: 400; */
  /* font-style: normal; */
}
.rtl html,
.rtl body,
.rtl div,
.rtl span,
.rtl applet,
.rtl object,
.rtl iframe,
.rtl h1,
.rtl h2,
.rtl h3,
.rtl h4,
.rtl h5,
.rtl h6,
.rtl p,
.rtl blockquote,
.rtl div pre,
.rtl a,
.rtl abbr,
.rtl acronym,
.rtl address,
.rtl big,
.rtl cite,
.rtl code,
.rtl del,
.rtl dfn,
.rtl em,
.rtl font,
.rtl img,
.rtl ins,
.rtl kbd,
.rtl q,
.rtl s,
.rtl samp,
.rtl small,
.rtl strike,
.rtl strong,
.rtl sub,
.rtl sup,
.rtl tt,
.rtl var,
.rtl b,
.rtl u,
.rtl i,
.rtl center,
.rtl dl,
.rtl dt,
.rtl dd,
.rtl ol,
.rtl ul,
.rtl li,
.rtl figure,
.rtl header,
.rtl nav,
.rtl section,
.rtl article,
.rtl aside,
.rtl footer,
.rtl figcaption,
.rtl section,
.rtl div {
  font-family: "Cairo", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: 'Open Sans', sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-weight: 700;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 30px;
}

a {
  text-decoration: none;
}

img {
  width: 100%;
  overflow: hidden;
}
table td{
  vertical-align: middle;
}
/* 
---------------------------------------------
Global Styles
--------------------------------------------- 
*/

html,
body {
  font-family: 'Open Sans', sans-serif;
}

::selection {
  background: #5b03e4;
  color: #fff;
}

::-moz-selection {
  background: #5b03e4;
  color: #fff;
}

.templatemo-feature {
  max-width: 66px;
  border-radius: 50%;
}

.main-button a {
  font-size: 14px;
  color: #fff;
  background-color: #5b03e4;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 25px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}
.btnLevel{
  font-size: 14px;
  color: #fff;
  background-color: #5b03e4;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 10px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
}
.amrcat-cap-content button{
  font-size: 14px;
  color: #fff;
  background-color: #5b03e4;
  padding: 2px 10px ;
  display: inline-block;
  border-radius: 10px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
  border: none;
  outline: none;

}
.btnLevel:hover{
  background-color: #fff;
color: #5b03e4;
border: 1px solid #5b03e4;
}
.main-button a:hover {
  background-color: #5b03e4;
  color: #fff;
  opacity: 0.8;
}

.second-button a {
  font-size: 14px;
  color: #fff;
  background-color: #c03afe;
  padding: 12px 30px;
  display: inline-block;
  border-radius: 25px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
}

.second-button a:hover {
  background-color: #c03afe;
  color: #fff;
  opacity: 0.8;
}

.section {
  padding-top: 30px;
  margin-top: 90px;
}


.section-heading {
  margin-bottom: 30px;
}

.section-heading .line-dec {
  width: 60px;
  height: 2px;
  margin-bottom: 20px;
  background: rgb(85, 0, 227);
  background: linear-gradient(90deg, rgba(85, 0, 227, 1) 0%, rgba(198, 61, 255, 1) 100%);
}

.section-heading h2 {
  color: #2a2a2a;
  font-size: 30px;
  text-transform: capitalize;
  text-decoration: none;
  margin-bottom: 30px;
  line-height: 44px;
}

.section-heading h2 em {
  color: #5b03e4;
  font-style: normal;
}

.section-heading h2 span {
  color: #c03afe;
}

/* 
---------------------------------------------
Pre-loader Style
--------------------------------------------- 
*/

.js-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.99);
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.js-preloader.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@-webkit-keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@-webkit-keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

.preloader-inner {
  position: relative;
  width: 142px;
  height: 40px;
  background: transparent;
}

.preloader-inner .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #5b03e4;
  border-radius: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: dot 2.8s infinite;
  animation: dot 2.8s infinite;
}

.preloader-inner .dots {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  -webkit-animation: dots 2.8s infinite;
  animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  background: #5b03e4;
  border-radius: 50%;
}



/* 
---------------------------------------------
Header Style
--------------------------------------------- 
*/

.background-header {
  background-color: #fff;
  height: 80px !important;
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area {
  position: absolute;
  background: rgb(85, 0, 227);
  background: linear-gradient(90deg, rgba(85, 0, 227, 1) 0%, rgba(198, 61, 255, 1) 100%);
  height: 80px;
  top: 57px;
  left: 0;
  right: 0;
  z-index: 100;
  -webkit-transition: all .5s ease 0s;
  -moz-transition: all .5s ease 0s;
  -o-transition: all .5s ease 0s;
  transition: all .5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
  display: flex
}

.header-area .main-nav .logo {
  margin-top: 25px;
  flex-basis: 20%;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.background-header .main-nav .logo {
  margin-top: 10px;
}

.header-area .main-nav .nav {
  flex-basis: 80%;
  height: 110px;
  margin-top: 0px;
  margin-right: 0px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.nav {
  justify-content: right;
}

.header-area .main-nav .nav li:first-child {
  padding-left: 60px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li {
  padding-left: 30px;
  padding-right: 30px;
}

.header-area .main-nav .nav li:nth-child(4) {
  padding-right: 45px !important;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  height: 110px;
  line-height: 110px;
  border: transparent;
  letter-spacing: 1px;
}

.background-header .main-nav .nav li a {
  height: 80px;
  line-height: 80px;
}

.header-area .main-nav .border-button {
  flex-basis: 20%;
  margin-top: 13px;
  text-align: right;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover,
.background-header .main-nav .nav li.has-sub ul.sub-menu li:last-child a:hover {
  padding-left: 25px !important;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: #fff;
  opacity: 0.8;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #fff;
  opacity: 0.8;
}

.header-area .main-nav .nav li.has-sub {
  position: relative;
  padding-right: 15px;
}

.header-area .main-nav .nav li.has-sub:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: 30px;
  top: 45px;
}

.background-header .main-nav .nav li.has-sub:after {
  top: 32px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu {
  position: absolute;
  width: 160px;
  border-radius: 0px 0px 5px 5px;
  background-color: #eee;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 110px;
  opacity: 0;
  transition: all .3s;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
}

.background-header .main-nav .nav li.has-sub ul.sub-menu {
  top: 80px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li a {
  opacity: 1;
  display: block;
  background: #eee;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.has-sub ul.sub-menu li:last-child a {
  border-bottom: none;
}

.header-area .main-nav .nav li.has-sub ul li a:hover {
  background: #fff;
  color: #5b03e4 !important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.has-sub ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.has-sub:hover ul.sub-menu {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 20px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 17px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #fff;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #fff;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #fff;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #fff;
}

.header-area.header-sticky .nav li a.active {
  color: #fff;
}

.navvvv {
  background-color: transparent !important;
  position: absolute;
  top: 0;
  z-index: 2222;

  width: 100%;
  flex-direction: column;
}

.ImgNav {
  position: relative;
  top: 0;
}

.ImgNav img {
  position: absolute;
  top: -90px;
  width: 100x;
}

.rtl .navvvv {
  direction: rtl;
}


.navyy {
  position: relative;
  /* width: 100%; */

  /* border-bottom: 1px solid #373745; */
  z-index: 22;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
}

.rtl .navyy {
  margin-left: unset;
  margin-right: auto;
}

.navbar p,
.navbar a {
  line-height: 10px;
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.topNav {
  /* background-color: #5b03e4; */
  display: flex;
  width: 100%;
  margin-top: -5px;
  padding: 2px 0;
  justify-content: center;
}

.navyy {
  width: 80%;
  padding: 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  margin-left: auto;

}

.navyy .navlinkk svg {
  display: none;
}


.logo img {
  width: 200px !important;
}



.darkMode label {
  width: 60px;
  /* Set width to 100px */
  height: 30px;
  position: relative;
  display: block;
  background: #ebebeb;
  border-radius: 200px;
  box-shadow: inset 0px 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
}

.darkMode label:after {
  content: "";
  width: 25px;
  /* Adjust the width accordingly */
  height: 25px;
  position: absolute;
  top: 3px;
  left: 2px;
  background: linear-gradient(180deg, #fff, #fff);
  border-radius: 180px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}

.darkMode input {
  width: 0;
  height: 0;
  visibility: hidden;
}

.darkMode input:checked+label {
  background: #170c1c;
}

.darkMode input:checked+label:after {
  left: 55px;
  /* Adjust the position accordingly */
  transform: translateX(-100%);
  background: linear-gradient(180deg, #777, #3a3a3a);
}

.darkMode label,
.darkMode label:after {
  transition: 0.3s
}

.darkMode label:active:after {
  width: 60px;
  /* Adjust the width accordingly */
}

/* 
.rtl .logoP img {

  right: 0px;
} */

.navyy>div {
  display: flex;
}

/* 
.imglogo {
  width: 100px;
} */


.navlinkk {
  font-weight: 500;
  color: #fff !important;

}

#basic-nav-dropdown {
  color: #fff !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 16px !important;
}

.rtl #basic-nav-dropdown .dropdown-item {
  text-align: right !important;
}

.rtl .navyy #basic-nav-dropdown {
  text-align: right !important;
}

.rtl .navyy .toggle-button {
  text-align: right;
  margin-right: 40px;
}

.dropdown-menu.show a {
  color: #020415 !important;
}

.sun {
  font-size: 30px !important;
  color: #000 !important;
  margin-left: 30px;
}

.moon {
  font-size: 25px !important;
  color: #fff;
  margin-top: 5px;
  margin-left: 5px;
}

.iconnm {
  font-size: 28px;
  color: #fff;

}
.rtl .toggle-password{
  right: unset;
  left: 10px;
}
.navbar img {
  width: 170px;
  margin-left: 40px;
  margin-top: 0px;
}
.rtl .googlea div , .rtl .facea div{
  text-align: right !important;
}
/* .offcanvas-body .navlinkk{
  color: #000 !important;
} */
/* .offcanvas-body .navyy .navLinks{
  flex-direction: column;
} */
/* 
.offcanvas-body .sun {
  color: #000;
}
.offcanvas-body .moon {
  color: #000;
}

.offcanvas-body .iconnm {
  color: #000;

} */
.fixed-buttons {
  bottom: 52px;
  display: flex;
  filter: drop-shadow(5px 5px 5px #00000024);
  flex-direction: column;
  gap: 10px;
  position: fixed;
  right: 34px;
  z-index: 22222222222;
}

.fixed-button {
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, .16);
  background-color: #5b03e4;
  color: white;
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
}

.fixed-button:hover {
  background-color: #715AEB;
}

.ticketDetail {
  min-height: 100vh;
  padding: 50px 0;
}
.updateprofilee p{
  text-align: left;
}
.rtl .updateprofilee p{
  text-align: right;
}
.rtl .main-banner .updateprofilee p{
  padding-right: 0;
}
.exchangeLogo {
  text-align: center;
  font-size: 52px;
  color: #c03afe;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 20px;
}

.exchangeLogo span {
  font-style: normal;
  color: #000 ;
}
/* For screens wider than 900px */
@media (min-width: 901px) {
  .mobile-view {
    display: none;
  }

  .table-view {
    display: block;
  }

  .infos .main-content {
    border-radius: 0 !important;
  }

}

@media (max-width: 900px) {


  .transaction-row {
    border-top: 14px solid #5b03e4;
    background-color: white;

  }

  .transaction-item {
    font-size: 14px;
    margin-bottom: 5px;
    text-align: left;

  }

  .table-view {
    display: none;
  }

  .mobile-view {
    display: block;
  }

  .transaction-row {
    display: flex;
    flex-wrap: wrap;

    margin-bottom: 10px;
  }

  .transaction-item {
    padding: 5px 20px;

    border-bottom: 1px solid #ccc;
    /* Border for each item */

    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;
    /* padding: 5px 0; */
  }

  .transaction-item img {
    height: 40px;
    margin-right: 5px;
    object-fit: contain;
  }

  .transaction-item.img span {
    display: flex;
    align-items: center;
  }

  .transaction-item strong {
    flex: 0 0 100px;
  }
}

@media (max-width: 700px) {
  footer {
    background-color: #f8f9fa;
    padding: 20px 0 3px;
  }

  footer h3 {
    cursor: pointer;
    margin-top: 10px;
  }

  footer h3 svg {
    display: block !important;
  }

  .accordion-content {
    display: none;
  }

  .accordion-content.open {
    display: block;
  }

  footer h3 {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    font-size: 14px !important;
    /* font-size: 1.2rem; */
    margin-bottom: 0.5rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.744);
  }

  footer a {
    text-align: left;
  }

  footer p {
    margin: 0;
  }

  .divicons {
    display: flex;
    gap: 10px;
  }

  .divicons div {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 50%;
    transition: background-color 0.3s;
  }

  .divicons div:hover {
    background-color: #e2e2e2;
  }
}

@media (max-width: 1024px) {
  .moon {
    color: #fff !important;
    margin-left: -30px;
  }

  .dnoned {
    display: none !important;
  }

  .collapse.show .navlinkk {
    color: #000 !important;

  }

  .main-banner form .col-lg-12 {
    margin-top: 0;
  }

  .methodsslider img {
    width: 70px;
  }

  .navyy div {
    flex-direction: column;
  }

  .header-area .container {
    padding: 0px;
  }

  .header-area .logo {
    margin-left: 0px;
  }

  .header-area .menu-trigger {
    display: block !important;
  }

  .header-area .main-nav {
    overflow: hidden;
  }

  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }

  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }

  .header-area .main-nav .nav li {
    padding-left: 10px;
    padding-right: 10px;
  }

  .header-area .main-nav:before {
    display: none;
  }

  .offcanvas.offcanvas-end.show .navlinkk,
  .offcanvas.offcanvas-end.show .moon,
  .offcanvas.offcanvas-end.show .iconnm,
  .offcanvas.offcanvas-end.show .sun,
  #basic-nav-dropdown {
    color: #000 !important;
  }

  .row.data-box-inner {
    display: flex !important;
    overflow: scroll;
    flex-wrap: nowrap !important;
  }

  .row.data-box-inner .col-lg-2 {
    width: 100px;
    text-align: center;
  }

  .projects .swiper-button-prev {
    margin-left: -65px !important;
  }

  .navyy {
    width: 100%;
  }

  .navyy .navlinkk {
    display: flex;
    margin-top: 10px;
    align-items: center;
  }

  .navyy {
    align-items: unset;
  }

  .navyy .toggle-button {
    text-align: left;
    /* padding: 20px 10px; */
    margin-left: 30px;
  }

  .navyy .navLinks {
    margin-left: unset;
  }

  .navyy .navlinkk svg {
    display: block;
    margin-right: 20px;
  }

  /* .main-banner h4 span {
    color: #fff !important;
  } */
  .navyy #basic-nav-dropdown {
    text-align: left;
  }

  .navyy svg {
    color: #000;
  }

  .collapse.show .nav-item {
    padding: 5px 20px;
  }

  .custom-nav-item {
    border-bottom: 1px solid #00000070;

  }

  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }

  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #5b03e4 !important;
  }

  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
    transition: all 0s;
  }

  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }

  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }

  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    height: auto;
  }

  .header-area .main-nav .nav li.has-sub:after {
    color: #3B566E;
    right: 30px;
    font-size: 14px;
    top: 15px;
  }

  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (max-width: 767px) {
  .navbar img {
    width: 130px;
    top: -70px;
    margin-left: 0px;
  }

  body.dark-mode .navyy {
    background-color: #170c1c;
  }

  body.dark-mode .collapse.show .navlinkk,
  body.dark-mode .collapse.show .navyy .navlinkk svg,
  body.dark-mode .navyy #basic-nav-dropdown,
  body.dark-mode .navyy svg {
    color: #fff !important;
  }

  .header-area {
    padding-bottom: 5px !important;
  }

  .header-area .main-nav .nav {
    height: auto;
    flex-basis: 100%;
  }

  .header-area .main-nav .logo {
    position: absolute;
    left: 30px;
    top: 0px;
  }

  .infos .container {
    padding: 0 !important;
  }

  .infodifferr {
    overflow-x: scroll;
  }
.darkMode{
  padding-left: 40px !important;
}
.rtl .darkMode {
  padding-left: unset !important;
  padding-right: 40px !important;

}
.rtl .navyy .navlinkk svg{
  margin-right: 5px;
  margin-left: 5px;
}
  .infodifferr p {
    line-height: 12px;
    font-size: 12px;
  }

  .background-header .main-nav .logo {
    top: 0px;
  }

  .background-header .main-nav .border-button {
    top: 0px !important;
  }

  .header-area .main-nav .border-button {
    position: absolute;
    top: 15px;
    right: 70px;
  }

  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: #5b03e4 !important;
    opacity: 1;
  }

  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }

  .custom-navbar-collapse {
    background-color: #f8f9fa;
    /* Match the background color of the navbar */
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .custom-nav-item {
    width: 100%;
  }

  .custom-nav-link {
    width: 100%;
    padding: 10px 20px;
  }

  .header-area {
    background-color: #f7f7f7;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }

  .header-area .container {
    padding: 0px;
  }

  .header-area .logo {
    margin-left: 0px;
  }

  .header-area .menu-trigger {
    display: block !important;
  }

  .header-area .main-nav {
    overflow: hidden;
  }

  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }

  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }

  /* .header-area.header-sticky .nav {
    margin-top: 100px !important;
  } */

  .background-header.header-sticky .nav {
    margin-top: 80px !important;
  }

  .offcanvas.offcanvas-end.show {
    z-index: 2222;
  }

  .offcanvas.offcanvas-end.show .navlinkk,
  .offcanvas.offcanvas-end.show .moon,
  .offcanvas.offcanvas-end.show .iconnm,
  .offcanvas.offcanvas-end.show .sun,
  #basic-nav-dropdown {
    color: #000 !important;
  }

  .row.data-box-inner {
    display: flex !important;
    overflow: scroll;
    flex-wrap: nowrap !important;
  }

  .row.data-box-inner .col-lg-2 {
    width: 100px;
    text-align: center;
  }

  .projects .swiper-button-prev {
    margin-left: -65px !important;
  }

  /* .main-banner h4 span {
    color: #fff !important;
  } */

  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #eee;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }

  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #5b03e4 !important;
  }

  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
    transition: all 0s;
  }

  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }

  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }

  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    height: auto;
  }

  .header-area .main-nav .nav li.has-sub:after {
    color: #3B566E;
    right: 30px;
    font-size: 14px;
    top: 15px;
  }

  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (max-width: 398px) {
  .navvvv {
    flex-direction: row;
  }

  .navbar-toggler.collapsed {
    border: none !important;
    box-shadow: none !important;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
    border: none !important;

  }



  .header-area {
    padding: 0px;
  }

  .navbar img {
    width: 125px;
    margin-left: 5px;
  }

  .ImgNav img {
    top: -65px;
  }

  .main-title h2 {
    font-size: 24px !important;
  }

  .sub-title h3 {
    font-size: 12px;
    text-align: center;
  }
}

.pre-header {
  background-color: #fff;
  padding: 15px 0px;
}

.pre-header ul li {
  display: inline-block;
}

.pre-header .left-info ul li:last-child {
  margin-right: 0px;
  padding-right: 0px;
  border-right: none;
}

.pre-header .left-info ul li {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid #dfb6ff;
}

.pre-header .left-info ul li a {
  font-size: 14px;
}

.pre-header .left-info ul li a i {
  font-size: 18px;
  margin-right: 10px;
}

.pre-header ul li a {
  color: #a586bc;
  transition: all .4s;
}

.pre-header .social-icons {
  text-align: right;
}


.pre-header .social-icons ul li {
  margin-left: 10px;
}

.pre-header .social-icons ul li a {
  font-size: 18px;
}

.pre-header ul li a:hover {
  color: #c03afe;
}

/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/

.main-banner {
  position: relative;
  padding: 195px 0px 185px 0px;
}

.main-banner form {
  background-color: white;
  padding: 60px;
  border-radius: 23px;
  box-shadow: 20px 20px 10px rgba(0, 0, 0, 0.277);
  min-height: 400px;
  /* margin-top: -50px; */
}

.main-banner form input,
.main-banner form input::placeholder {
  color: #000 !important;
}

.main-banner form .col-lg-6 {
  margin-top: 10px;
}

.main-banner form .col-lg-12 {
  margin-top: 10px;
}

.main-banner::after {
  content: url(./images/banner-right-Photoroom.png);
  top: -1px;
  right: 0;
  position: absolute;
  width: 861px;
  height: 746px;
  z-index: -1;
}

.main-banner::before {
  content: url(./images/banner-left.png);
  top: -1px;
  left: 0;
  position: absolute;
  width: 194px;
  height: 655px;
  z-index: -1;
}

.main-banner h6 {
  font-size: 20px;
  color: #5b03e4;
  text-transform: uppercase;
  font-weight: 700;
}

.main-banner .line-dec {
  margin: 30px 0px 20px 0px;
  width: 210px;
  height: 2px;
  background-color: #decdfa;
}

.main-banner h4 {
  font-size: 52px;
  color: #2a2a2a;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 20px;
}

.main-banner h4 em {
  font-style: normal;
  color: #5b03e4;
}

.main-banner h4 span {
  color: #c03afe;
}

.main-banner p {
  padding-right: 25%;
  margin-bottom: 30px;
  color: #000;
  font-size: 18px;
}

.main-banner .main-button {
  display: inline-block;
}

.main-banner span {
  display: inline-block;
  margin: 0px 10px 0px 10px;
  /* color: #7a7a7a; */
  font-weight: 500;
}

.main-banner .second-button {
  display: inline-block;
}

/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.services {
  position: relative;
  overflow: hidden;
  padding-bottom: 100px;
}

.services .section-heading h2 {
  padding-right: 30px;
}

.services::before {
  content: url(./images/about.webp);
  top: 120px;
  left: 0;
  position: absolute;
  width: 844px;
  height: 714px;
  z-index: -1;
}

.services::after {
  content: url(./images/contact-left.png);
  bottom: -150px;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.services .service-item {
  border: 1px solid #f3d7ff;
  border-radius: 23px;
  padding: 30px;
  background-color: #fff;
  margin-bottom: 30px;
  transition: all .4s;
}

.services .service-item h4 {
  font-size: 20px;
  color: #2a2a2a;
  margin-top: 20px;
  line-height: 30px;
  transition: all .4s;
}

.services .service-item:hover {
  border-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}

.services .service-item:hover h4 {
  color: #5b03e4;
}

/* 
---------------------------------------------
Projects Style
--------------------------------------------- 
*/
.projects {
  padding-top: 80px;
}

.projects p {
  color: #000;
}

.projects .section-heading {
  margin-bottom: 80px;
}

.projects .item {
  border-radius: 23px;
  overflow: hidden;
  box-shadow: 20px 0px 20px #f3d7ff;
  margin: 20px;
}

body.dark-mode .projects .item {
  box-shadow: none;
}

body.dark-mode .rtl .moon {
  margin-left: unset !important;
  margin-right: 45px;
}

body.dark-mode form p {
  color: #000 !important;
}

.projects .item .down-content {
  background-color: #fff;
  border: 1px solid #f3d7ff;
  border-radius: 0px 0px 23px 23px;
  padding: 45px 30px;
  position: relative;
}

.userReview {
  color: #5b03e4;
  font-size: 40px;
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

.projects .item .down-content h4 {
  font-size: 20px;
  color: #2a2a2a;
  line-height: 30px;
  width: 75%;
}

.projects .swiper-button-next {
  right: 0;
  top: 24px;
}

.projects .swiper-button-prev {
  left: 90%;
  top: 24px;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 26px;
  margin-left: 15px;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 26px;
  margin-left: 15px;
}

.projects .swiper-button-next,
.projects .swiper-button-prev {
  width: 46px;
  height: 46px;
  line-height: 42px;
  font-size: 24px;
  display: inline-block;
  color: #fff;
  background-color: #5b03e4;
  border-radius: 50%;
  opacity: 0.5;
  transition: all .3s;
}

.projects .swiper-button-prev {
  margin-left: 15px;
}

.projects .swiper-button-next:hover,
.projects .swiper-button-prev:hover {
  opacity: 1;
}

.projects .swiper-slide {
  margin-top: 80px;
}

/* 
---------------------------------------------
Infos Style
--------------------------------------------- 
*/

.infos {
  background-image: url(./images/infos-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 120px 0px;
  margin-top: 120px;
  background-attachment: fixed;
}

.infodifferr {
  margin-top: 30px;
  min-height: 80vh;
}

.infodifferr img {
  object-fit: contain;
  width: 50px;
}

.infodifferr table p {
  text-align: center;
}

.infos .main-content {
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}

.infoHome .main-content {
  overflow-x: scroll !important;

}

.infos thead th {
  padding: 20px 0 !important;
  background-color: #5b03e4;
}

.infos .main-content th p {
  color: #fff !important;
}

.infos .main-content td p {
  margin-top: 10px;
  color: #000 !important;
}

.infos .main-content .left-image {
  background-image: url(./images/left-infos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100%;
}

.infos .main-content .left-image img {
  display: none;
}

.infos .main-content .section-heading {
  padding: 60px 60px 30px 60px;
}

.infos .main-content .skills {
  padding: 0px 60px;
}

.infos .main-content .skill-slide {
  position: relative;
  width: 100%;
  background-color: #f9ebff;
  height: 10px;
  border-radius: 5px;
  margin-bottom: 60px;
}

.infos .main-content .skill-slide .fill {
  height: 10px;
  background-color: #5b03e4;
  border-radius: 5px;
}

.infos .main-content .skill-slide h6 {
  position: absolute;
  color: #5b03e4;
  font-size: 15px;
  left: 0;
  top: -25px;
}

.infos .main-content .skill-slide span {
  position: absolute;
  color: #5b03e4;
  font-size: 15px;
  font-weight: 700;
  top: -25px;
}

.infos .main-content .marketing .fill {
  width: 90%;
}

.infos .main-content .digital .fill {
  width: 80%;
}

.infos .main-content .media .fill {
  width: 95%;
}

.infos .main-content .marketing span {
  right: 10%;
}

.infos .main-content .digital span {
  right: 20%;
}

.infos .main-content .media span {
  right: 5%;
}

.infos .main-content p.more-info {
  margin-top: -15px;
  padding: 0px 60px 60px 60px;
}

/* 
---------------------------------------------
Contact Style
--------------------------------------------- 
*/

.contact-us {
  position: relative;
}

.contact-us img {
  width: 100%;
}

.contact-us::before {
  content: url(./images/contact-left.png);
  top: -60px;
  left: 0;
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.contact-us::after {
  content: url(./images/contact-left.png);
  bottom: -90px;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.contact-us .contact-us-content {
  border-radius: 23px;
  padding: 60px;
  /* background-color: #fff; */
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10); */
}

.password-container {
  position: relative;
}

.toggle-password {
  position: absolute;
  top: 40%;
  color: #000 !important;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.contact-us .contact-us-content #contact-form {
  padding: 60px;
  border-radius: 23px 23px 0px 0px;
  border: 1px solid #f3d7ff;
}

#contact-form h5 {
  color: #000;
  margin-top: 30px;
  font-weight: 500;
  font-size: 18px;
}

#contact-form h5 a {
  color: #5b03e4;
  text-decoration: underline !important;
}

#contact-form .section-heading {
  text-align: center;
}

#contact-form input,
#contact-form select {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: #f9ebff;
  border: none;
  outline: none;
  padding: 0px 15px;
  font-size: 14px;
  color: #2a2a2a !important;
  font-weight: 500;
  margin-bottom: 15px;
}

#contact-form input::placeholder {
  color: #2a2a2a !important;
}

#contact-form textarea {
  width: 100%;
  height: 120px;
  border-radius: 23px;
  background-color: #f9ebff;
  border: none;
  outline: none;
  padding: 15px;
  font-size: 14px;
  color: #2a2a2a !important;
  font-weight: 500;
  margin-bottom: 15px;
}

#contact-form textarea::placeholder {
  color: #2a2a2a !important;
}

#contact-form button {
  border: none;
  height: 46px;
  background-color: #5b03e4;
  width: 100%;
  border-radius: 23px;
  color: #fff;
  transition: all .4s;
}

#contact-form button:hover {
  opacity: 0.8;
}

.contact-us .contact-us-content #second-form {
  padding: 60px;
  border-radius: 23px 23px 0px 0px;
  border: 1px solid #f3d7ff;
}

#second-form h5 {
  color: #000;
  margin-top: 30px;
  font-weight: 500;
  font-size: 18px;
}

#second-form h5 a {
  color: #5b03e4;
  text-decoration: underline !important;
}

#second-form .section-heading {
  text-align: center;
}

#second-form input,
#second-form select {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: #f9ebff;
  border: none;
  outline: none;
  padding: 0px 15px;
  font-size: 14px;
  color: #2a2a2a !important;
  font-weight: 500;
  margin-bottom: 15px;
}

#second-form input::placeholder {
  color: #2a2a2a !important;
}

#second-form textarea {
  width: 100%;
  height: 120px;
  border-radius: 23px;
  background-color: #f9ebff;
  border: none;
  outline: none;
  padding: 15px;
  font-size: 14px;
  color: #2a2a2a !important;
  font-weight: 500;
  margin-bottom: 15px;
}

#second-form textarea::placeholder {
  color: #2a2a2a !important;
}

#second-form button {
  border: none;
  height: 46px;
  background-color: #5b03e4;
  width: 100%;
  border-radius: 23px;
  color: #fff;
  transition: all .4s;
}

#second-form button:hover {
  opacity: 0.8;
}

.contact-us-content .more-info {
  text-align: center;
  background: rgb(85, 0, 227);
  background: linear-gradient(90deg, rgba(85, 0, 227, 1) 0%, rgba(198, 61, 255, 1) 100%);
  border-radius: 0px 0px 23px 23px;
  padding: 45px 30px 15px 30px;
}

.contact-us-content .more-info .info-item {
  text-align: center;
  margin-bottom: 30px;
}

.contact-us-content .more-info i {
  font-size: 32px;
  color: #fff;
  margin-bottom: 15px;
}

.contact-us-content .more-info h4 a {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
}


/* 
---------------------------------------------
Page Heading Style
--------------------------------------------- 
*/

.page-heading {
  padding: 120px 0px;
  margin-top: 110px;
}

.page-heading::before {
  content: url(./images/banner-left.png);
  top: -1px;
  left: 0;
  position: absolute;
  width: 194px;
  height: 655px;
  z-index: -1;
}

.page-heading h6 {
  font-size: 20px;
  color: #5b03e4;
  text-transform: uppercase;
  font-weight: 700;
}

.page-heading .line-dec {
  margin: 30px 0px 20px 0px;
  width: 210px;
  height: 2px;
  background-color: #decdfa;
}

.page-heading h4 {
  font-size: 52px;
  color: #2a2a2a;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 20px;
}

.page-heading h4 em {
  font-style: normal;
  color: #5b03e4;
}

.page-heading h4 span {
  color: #c03afe;
}

.page-heading p {
  padding-right: 25%;
  margin-bottom: 30px;
}

.page-heading .main-button {
  display: inline-block;
}

.page-heading span {
  display: inline-block;
  margin: 0px 10px 0px 10px;
  color: #7a7a7a;
}

.page-heading .second-button {
  display: inline-block;
}

/* 
---------------------------------------------
Infos Style
--------------------------------------------- 
*/

.video-info {
  background-image: url(./images/video-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 60px;
}

.video-info .video-thumb {
  position: relative;
  border-radius: 23px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
  margin-top: -60px;
}

.video-info .video-thumb a {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-23px, -23px);
  background-color: #fff;
  border-radius: 50%;
  color: #5b03e4;
  display: inline-block;
  text-align: center;
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.video-info .section-heading {
  padding: 60px 15px 30px 15px;
}

.video-info .section-heading h2 {
  color: #fff;
  padding-right: 30px;
}

.video-info .section-heading .line-dec {
  background: #fff;
}

.video-info .section-heading p {
  color: #fff;
}

.video-info .skills {
  padding: 0px 15px;
}

.video-info .skill-slide {
  position: relative;
  width: 100%;
  background-color: rgba(249, 235, 255, 0.1);
  height: 10px;
  border-radius: 5px;
  margin-bottom: 60px;
}

.video-info .skill-slide .fill {
  height: 10px;
  background-color: #5b03e4;
  border-radius: 5px;
}

.video-info .skill-slide h6 {
  position: absolute;
  color: #fff;
  font-size: 15px;
  left: 0;
  top: -25px;
}

.video-info .skill-slide span {
  position: absolute;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  top: -25px;
}

.video-info .marketing .fill {
  width: 90%;
}

.video-info .digital .fill {
  width: 80%;
}

.video-info .media .fill {
  width: 95%;
}

.video-info .marketing span {
  right: 10%;
}

.video-info .digital span {
  right: 20%;
}

.video-info .media span {
  right: 5%;
}

/*
---------------------------------------------
Happy Clients Style
---------------------------------------------
*/

.happy-clients {
  position: relative;
}

.happy-clients::before {
  content: url(./images/contact-left.png);
  top: -60px;
  left: 0;
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.happy-clients::after {
  content: url(./images/contact-left.png);
  bottom: -90px;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.happy-clients .section-heading {
  text-align: center;
  margin-bottom: 80px;
}

.happy-clients .section-heading .line-dec {
  margin: 0 auto 20px auto;
}

.happy-clients .naccs {
  position: relative;
  overflow: hidden;
}

.happy-clients .naccs .menu {
  border: 1px solid #e0e0e0;
  background-color: #fff;
  padding: 25px 0px;
  border-radius: 23px;
  margin-bottom: 60px;
}

.happy-clients .naccs .menu div {
  border-right: 1px solid #e0e0e0;
  width: 24.6%;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  cursor: pointer;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.happy-clients .naccs .menu div.last-item {
  border-right: none;
}

.happy-clients .naccs .menu div.active {
  color: #5b03e4;
}

.happy-clients ul.nacc {
  position: relative;
  min-height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.happy-clients ul.nacc li {
  overflow: hidden;
  opacity: 0;
  transform: translateX(50px);
  position: absolute;
  list-style: none;
  transition: 1s all cubic-bezier(0.075, 0.82, 0.165, 1);
}

.happy-clients ul.nacc li img {
  padding-left: 45px;
}

.happy-clients ul.nacc li h4 {
  font-size: 30px;
  color: #2a2a2a;
  margin-bottom: 25px;
}

.happy-clients ul.nacc li .line-dec {
  background-color: #5b03e4;
  width: 60px;
  height: 2px;
  margin-bottom: 25px;
}

.happy-clients ul.nacc li .info {
  margin-top: 30px;
}

.happy-clients ul.nacc li .info span {
  display: inline-block;
  background-color: #5b03e4;
  border-radius: 20px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  padding: 0px 20px;
  margin-right: 20px;
  margin-bottom: 30px;
}

.happy-clients ul.nacc li .info span.last-span {
  margin-right: 0px;
}

.happy-clients ul.nacc li.active {
  position: relative;
  transition-delay: 0.3s;
  z-index: 2;
  opacity: 1;
  transform: translateX(0px);
}

/*
---------------------------------------------
CTA Style
---------------------------------------------
*/

.cta {
  background-image: url(./images/cta-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 80px 0px;
  position: relative;
  z-index: 15;
  margin-top: 120px;
}

.cta h4 {
  font-size: 30px;
  color: #fff;
  line-height: 44px;
}

.cta .main-button {
  text-align: right;
}

.cta .main-button a {
  background-color: #fff;
  color: #5b03e4;
  margin-top: 30px;
}

/*
---------------------------------------------
Happy Steps Style
---------------------------------------------
*/

.happy-steps {
  background-image: url(./images/video-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding: 80px 0px;
}

.happy-steps h2 {
  font-size: 30px;
  color: #fff;
  text-align: center;
  margin-bottom: 60px;
}

.happy-steps .steps {
  background-color: rgba(250, 250, 250, 0.2);
  padding: 30px;
  border-radius: 23px;
}

.happy-steps .steps .item {
  text-align: center;
  border-right: 1px solid rgba(250, 250, 250, 0.2);
  margin-right: -15px;
}

.happy-steps .steps .last-item {
  border-right: none;
  margin-right: 0px;
}

.happy-steps .steps .item h4 {
  font-size: 20px;
  color: #fff;
  margin-top: 15px;
}

/* 
---------------------------------------------
Most Asked Style
--------------------------------------------- 
*/

.most-asked {
  position: relative;
}

.most-asked::before {
  content: url(./images/contact-left.png);
  top: -60px;
  left: 0;
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.most-asked::after {
  content: url(./images/contact-left.png);
  bottom: -90px;
  right: 0;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
  width: 103px;
  height: 464px;
  z-index: -1;
}

.most-asked .section-heading {
  margin-bottom: 80px;
}

.most-asked .accordions .accordion {
  border-bottom: 1px solid #eee;
}

.most-asked .accordions .last-accordion {
  border-bottom: none;
}

.most-asked .accordion-head {
  padding: 35px 0px !important;
  font-size: 22px;
  font-weight: 700;
  color: #2a2a2a;
  cursor: pointer;
  transition: color 200ms ease-in-out;

}

@media screen and (min-width: 768px) {
  .most-asked .accordion-head {
    padding: 1rem;
    font-size: 1.2rem;
  }
}

.most-asked .accordion-head .icon {
  float: right;
  transition: transform 200ms ease-in-out;
}

.most-asked .accordion-head.is-open {
  color: #5b03e4;
  border-bottom: none;
}

.most-asked .accordion-head.is-open .icon {
  transform: rotate(45deg);
}

.most-asked .accordion-body {
  padding: 0px;
  overflow: hidden;
  height: 0;
  transition: height 300ms ease-in-out;
  border-bottom: 1px solid #fff;
}

.most-asked .accordions .content p {
  padding: 10px 0px 30px 0px;
  color: #000;
}

.most-asked #free-quote {
  border-radius: 23px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.10);
  padding: 60px;
  margin-left: 45px;
  background-color: #fff;
}

#free-quote .section-heading {
  margin-bottom: 40px;
}

#free-quote input {
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background-color: #f9ebff;
  border: none;
  outline: none;
  padding: 0px 15px;
  font-size: 14px;
  color: #2a2a2a;
  font-weight: 600;
  margin-bottom: 15px;
}

#free-quote input::placeholder {
  color: #2a2a2a;
}

#free-quote button {
  border: none;
  height: 46px;
  background-color: #5b03e4;
  width: 100%;
  border-radius: 23px;
  color: #fff;
  transition: all .4s;
}

#free-quote button:hover {
  opacity: 0.8;
}
.invitioncard p{
color: #5b03e4;
}
.invitioncard h3{
font-weight: 500 ;
color: #000;
}
/* 
---------------------------------------------
Responsive Style
--------------------------------------------- 
*/

body {
  overflow-x: hidden;
}
.modal.show .modal-dialog{
  transform: translateY(60%);
}
@media (max-width: 767px) {
  .pre-header {
    display: none;
  }

  .header-area {
    top: 0px;
  }

  .main-banner {
    margin-top: 100px;
  }

  .header-area .main-nav .nav li:nth-child(4) {
    padding-right: 0px !important;
  }

  .header-area .main-nav .nav li {
    background: #f7f7f7;
  }

  .header-area .main-nav .nav li.has-sub ul.sub-menu {
    display: none;
  }

  .main-banner {
    padding: 185px 0px;
  }
 .contactt.main-banner {
    padding: 15px 0px;
  }
  .main-banner h4 {
    font-size: 36px;
    line-height: 44px;
  }

  .main-banner span {
    margin: 0px 3px 0px 3px;
  }

  .main-banner .main-button a,
  .main-banner .second-button a {
    padding: 12px 20px;
  }

  .projects .item .down-content {
    padding: 25px 15px 15px 15px;
  }

  .projects .item .down-content h4 {
    text-align: center;
    width: 100%;
    font-size: 18px;
  }

  .projects .item .down-content a {
    top: 0;
    right: auto;
    left: 50%;
    transform: translate(-23px, -23px);
  }
  .exchangeLogo{
    font-size: 32px;
  }
}

@media (max-width: 992px) {
  .pre-header .left-info ul li {
    padding-right: 10px;
    margin-right: 10px;
  }

  .navbar p,
  .navbar a {
    line-height: 15px;
  }

  .topNav {
    margin-top: -1px;
  }

  .pre-header .left-info ul li a {
    font-size: 13px;
  }

  .main-banner .caption {
    padding: 10px 0px !important;
    text-align: center;
  }

  .main-banner p {
    padding-right: 0%;
  }

  .main-banner .line-dec {
    margin: 30px auto 20px auto;
  }

  .main-banner p {
    font-size: 14px;
    line-height: 20px;
  }

  .main-banner form {
    padding: 10px;
  }

  .main-banner.signinform {
    padding: 45px 0px;
  }

  .main-banner.signinform .row.justify-content-between {
    flex-direction: column-reverse;
  }

  .rowsmall {
    display: flex;
    /* flex-wrap: nowrap; */
    flex-direction: row;
  }

  .rowsmall .col-lg-6 {
    width: 50% !important;
  }

  .main-banner .section-heading h2 {
    display: none;
  }

  .projects .owl-nav {
    text-align: center;
    right: auto;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
  }

  .services::before {
    content: unset;
  }

  .projects .section-heading {
    text-align: center;
  }

  .projects .section-heading .line-dec {
    margin: 0px auto 20px auto;
  }

  .infos .main-content .left-image img {
    display: inline-block;
  }

  .contact-us .contact-us-content,
  .contact-us .contact-us-content #contact-form {
    padding: 30px;
  }

  .contact-us .contact-us-content,
  .contact-us .contact-us-content #second-form {
    padding: 30px;
  }

  .contact-us #map {
    margin-bottom: 30px;
  }

  .contact-us-content .more-info .info-item {
    margin-bottom: 45px;
  }

  section {
    overflow-x: hidden;
    overflow-y: clip;

  }

  .page-heading img {
    margin-top: 45px;
  }

  .video-info .section-heading {
    padding: 60px 0px 30px 0px;
  }

  /* .main-banner p {
    color: #fff;
  } */

  .video-info .skills {
    padding: 0px;
  }

  .happy-clients .naccs .menu div {
    width: 100% !important;
    margin: 15px 0px;
  }

  .happy-clients .naccs .menu {
    padding: 15px;
  }

  .happy-clients ul.nacc li img {
    padding-left: 0px;
    margin-top: 45px;
  }

  .cta h4,
  .cta .main-button {
    text-align: center;
  }

  .happy-steps .steps .item {
    margin-right: 0px;
    border-right: none;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(250, 250, 250, 0.2);
    ;
  }

  .happy-steps .steps .last-item {
    border-bottom: none;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .most-asked #free-quote {
    margin-left: 0px;
    margin-top: 45px;
    padding: 30px;
  }

  .main-banner::after {
    content: unset;
  }
}

@media (max-width: 1000px) {
  .happy-clients .naccs .menu div {
    width: 24.5%;
    font-size: 15px;
    border-right: none;
  }
}

@media (max-width: 1200px) {
  .services::before {
    top: 60%;
    transform: translateY(-50%);
  }
}

@media (max-width: 1400px) {
  .main-banner .caption {
    padding: 60px;
    border-radius: 23px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

/* .light-mode>* {
  background-color: #fefefe;
  color: #0b0c20;
} */

.dark-mode body {
  background-color: #020415;
  color: #ffffff;
}

.toggle-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}


a {
  text-decoration: none;
}

/* .light-mode a {
  text-decoration: none;
  color: #0b0c20;
} */






/****************carsoulhome***********************/

.slider-area {
  background: url(images/tranding.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 110px 0 0px;
  min-height: 100vh;
}

.slider-title h1 {
  font-size: 64px;
  font-weight: 800;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0;
  line-height: 1.3;
}

.slider-title p {
  font-size: 16px;
  font-weight: 400;
  width: 93%;
  padding: 15px 0;
  line-height: 1.5;
  color: #919da4;
}

.slider-button a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  background: #5b03e4;
  padding: 17px 35px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}

.slider-button a::before {
  position: absolute;
  content: "";
  top: 0;
  left: -90px;
  height: 100%;
  width: 100%;
  background: url(images/btn-shape.png);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  z-index: -1;
  transition: .5s;
}

.slider-button a:hover::before {
  opacity: 1;
  left: 0px;
}

.light-mode .slider-area {
  background: unset;
  /* background: url(images/background.png); */

}

/* .slider-title h1 {
color: #141227;
} */

.css-13cymwt-control {
  width: 100%;
  border-radius: 23px !important;
  background-color: #f9ebff !important;
  border: none !important;
  outline: none;
  font-size: 14px;
  color: #2a2a2a !important;
}

.light-mode .slider-title h1 {

  color: #020415;

}

.light-mode .slider-title p {

  color: #545252;
}

.light-mode .formExchange .singles-contacts-box {
  background-color: #fefefe;
  border: 1px solid #020415;
}

.light-mode .contact-area .singles-contacts-box .contact-title h3 {
  color: #020415;
}

.light-mode .contact-area .form_box input,
.light-mode .calculate {
  background-color: #fefefe;
  color: #020415;

}

.light-mode .contact-area .form_box select {
  background-color: #fefefe;
  color: #020415;
}

/*************sec2home*****************/
.feature-area {
  padding: 100px 0 115px;
  background-image: url(./images/infos-bg.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  /* padding: 120px 0px; */
  /* margin-top: 120px; */
}

.featureDiffer {
  background-image: unset !important;
}

.featuress .feature-thumb svg {
  font-size: 40px;
}

.light-mode .feature-area {
  background: unset;
  background-size: cover;
}

.feature-area .sub-title h3::before {
  left: 103%;
}

.feature-area svg {
  color: #fff;
}

.sub-title {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.main-title h2 {
  font-size: 40px;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  line-height: 1.3;
  margin: 12px 0 20px;
}

/* .light-mode .main-title h2 {
  color: #0b0c20;
} */
.infos thead th{
  color: #fff;
}
.sub-title h3 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: #ffffffe7;
  margin: 0 auto;
  position: relative;
}

.change-thumb {
  text-align: center;
}

.change-thumb img {
  object-fit: contain;
}

.sub-title h3::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 360px;
  right: 0;
  margin: 0 auto 0;
  height: 100%;
  width: 100%;
  color: #fff;
  background: url(images/about-1.png);
  background-repeat: no-repeat;
}

.sub-title h3::after {
  position: absolute;
  content: "";
  top: 3px;
  left: -155px;
  right: 0;
  margin: 0 auto 0;
  height: 100%;
  width: 100%;
  background: url(images/feature-shap.png);
  background-repeat: no-repeat;
}

.single-feature-box {
  text-align: center;
  background: rgb(255, 255, 255);
  padding: 30px 39px 0;
  border-radius: 7px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  min-height: 310px;
  border: 1px solid #5b03e4;
  box-shadow: 10px 10px 10px #5903e498;
}

.single-feature-box::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  /* background: url(images/service.png); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 1;
  transition: .5s;
}

.single-feature-box:hover::before {
  opacity: 1;
}

.light-mode .single-feature-box::before {
  opacity: 1;
}

.feature-thumb {
  display: inline-block;
  height: 80px;
  width: 80px;
  left: 0;
  right: 0;
  margin: auto;
  line-height: 80px;
  background: #1B1F39;
  border-radius: 50%;
}

.single-feature-box .feature-thumb {
  background: #5b03e4;
}

.feature-title h3 {
  font-size: 30px;
  font-weight: 600;
  color: #000;
  margin: 15px 0 15px;
}

.featureDiffer .feature-title h3 {
  color: #fff;
}

.feature-title p {
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  color: #919da4;
  margin: 0;
  margin-top: 20px;
  line-height: 1.3;
}

.dashboard .single-feature-box {

  min-height: 220px;

}

.dashboard .single-feature-box h3 {
  font-size: 20px;
}

/****************sec3home**************************/
.single-testimonial-box {
  background: rgb(21, 18, 44, 50%);
  padding: 40px 36px 30px 35px;
  border-radius: 8px;
  margin: 30px 0;
  position: relative;
  z-index: 1;
}

.single-testimonial-box::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url(images/tranding-2.png);
  opacity: 1;
  z-index: -1;
  transition: .5s;
  background-repeat: no-repeat;
  background-size: cover;
}

.single-testimonial-box:hover::before {
  opacity: 1;
}

.light-mode .single-testimonial-box::before {
  opacity: 1;

}

.reviewssec3 h2 {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  text-transform: capitalize;
}

.reviewssec3 h4 {
  font-size: 16px;
  font-weight: 400;
  color: #92a1b4;
  text-transform: capitalize;
}

.reviewssec3 p {
  font-size: 16px;
  font-weight: 400;
  color: #92a1b4;
  line-height: 1.5;
}

.reviewssec3 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 3px;
  color: #5b03e4;
}

.star {
  color: #d3d3d3;
  /* Gray color for empty stars */
  font-size: 24px;
  /* Adjust size as needed */
}

.star.filled {
  color: #715AEB;
  /* Gold color for filled stars */
}

/**************************************************/
.data-content h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 6px;
  color: #000;
}

.row.data-box-inner {
  /* background: #101227; */
  padding: 23px 0px 8px 20px;
  border-radius: 7px;
  margin-bottom: 20px;
  position: relative;
}

.data-box-inner p span {
  display: inline-block;
  color: #000 !important;
  font-size: 15px;
}

/* .data-table .sub-title h3,.testimonial-area .sub-title h3{
  margin-top: 40x;
} */
.data-table .sub-title h3::before {
  left: 110px;
}

.testimonial-area .sub-title h3::before {
  left: 100px;
}



.data-table h5 {
  margin-top: 20px;

}

.contact-area .sub-title h3::before {
  left: 110px;
}

.SigIn .sub-title h3::before {
  left: 80px;
}

/***********************************************************/
.about-area {
  padding: 50px 0;
}

.section-text p {
  font-size: 16px;
  color: #919da4;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
}

.about-area .sub-title h3 {
  margin-bottom: 30px;
  margin-top: 20px;
}

.about-area .sub-title h3::before {

  left: 80px;

}

.about-titles h4 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  line-height: 1.5;
  text-transform: capitalize;
}

.about-button a {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  padding: 15px 38px;
  background: #5b03e4;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  margin-top: 40px;
}

.about-button a::before {
  position: absolute;
  content: "";
  top: 0;
  left: -90px;
  height: 100%;
  width: 100%;
  background: url(images/btn-shape.png);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  z-index: -1;
  transition: .5s;
}

.about-button a:hover::before {
  opacity: 1;
  left: 0px;
}

.about-thumb {
  text-align: center;

}

.about-thumb img {
  width: 100%;
}

.light-mode .section-text p {
  color: #000;

}

.light-mode .about-tmb h4 {
  color: #000;

}

/***************************************/

footer {
  background-color: #020415;
  padding: 100px 0px 20px;
}

footer .copyright {
  text-align: center;
  margin-top: 80px;
}

footer h3 svg {
  display: none;
}

.light-mode footer {
  color: #fff;
}

.rtl footer {
  direction: rtl;
}

.footerimg {
  width: 150px !important;
  margin-top: -40px;
}

footer h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}

footer p {
  margin-top: 20px;
  color: #ffffffd7;
}

.light-mode footer p,
.light-mode footer a,
.light-mode footer h3 {
  color: #fff;
}

.divicons {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-top: 30px;

}

.divicons div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid #a1a1a1;
  color: #a1a1a1;
  margin: 0 5px;

}

.divicons div svg {
  margin: 0 15px;
  font-size: 25px;
  color: #fff;
}

.divicons div:hover {
  border: 1px solid #2c2c2c;
  color: #2c2c2c;
}


/***********************************************************************************/
.breadcumb-area {
  background-image: url(./images/video-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-bottom: 0px;
  min-height: 405px;

}


.breadcumb-title h1 {
  font-size: 42px;
  margin-bottom: 10px;
  color: #020415;
  font-weight: 700;
  text-transform: capitalize;
  text-align: center;
}



.breadcumb-content-text a {
  display: inline-block;
  text-decoration: none;
  color: #fff;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;

  text-align: center;
  display: flex;
  justify-content: center;
}

.breadcumb-content-text a span::before {
  position: absolute;
  content: "";
  top: 10px;
  right: -19px;
  height: 1px;
  width: 15px;
  background: #715AEB;
}

.breadcumb-content-text a span {
  margin-right: 20px;
  color: #020415;
  position: relative;
  z-index: 1;
}

/******************************************/

.aboutsec2 {
  text-align: center;
  color: white;
  padding: 60px 0px;
  background-color: #020415;

}

.aboutsec2 {
  padding: 100px 0 115px;
  background-image: url(./images/infos-bg.jpg) !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  /* padding: 120px 0px; */
  /* margin-top: 120px; */
}

.divmainsec2 {
  position: relative;
}

.divmainsec2>div {
  width: 90%;
  /* background-color: #1b1f39; */
  color: white;
  text-align: left;
  position: relative;
  padding: 50px 20px;

}

.divsec2222 {
  width: 100px;
  height: 100px;
  position: absolute;
  top: -20%;
  border-radius: 50%;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #5b03e4;
  z-index: 22;
  transition: all 0.5s ease-in-out;

}

.divmainsec2::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 80%;
  background-color: #fff;
  transition: .5s;
  border: 1px solid #5b03e4;
  box-shadow: 10px 10px 20px #5b03e4;
}

.aboutsec2 {
  color: #5b03e4;
}

.aboutsec2 h3 {
  color: #5b03e4;
}

.aboutsec2 p {
  color: #000;
  margin-top: 20px;
}


.divsec2222 {
  text-align: center;
}

.divsec2222 svg {
  font-size: 55px;
  color: #5b03e4;
  /* margin: auto; */
  text-align: center;
}

.divsec2222:hover {
  transform: translateY(-10px);
}

/****************************************/
.aboutsec3 {
  padding: 70px 0px;
  text-align: center;
  font-size: 20px;
}

.aboutsec3 .col-md-2 {
  border-right: 0.5px solid #a1a1a1;
}

.aboutsec3 img {
  width: 60px;
  height: 60px;
  filter: brightness(0%) invert(0.5);
  margin-bottom: 10px;
  transition: all 0.5s ease-in-out;

}

.aboutsec3 img:hover {
  transform: translateY(-10px);
}

.mmmm {
  border-right: 0px !important;

}

.aboutsec3 svg {
  color: #5b03e4;
  font-size: 50px;

}


.aboutsec3 p {
  color: #5b03e4;
  font-size: 22px;
  margin-top: 20px;
}



/******************contact us**************************/
.info-area {
  padding: 90px 0 0px;
  margin-top: -2px;
}

.info-area-title a {
  color: #000;
}

.info-area h2 {
  color: #020415;
  font-size: 45px;
  text-align: center;
  padding-bottom: 30px;
}

.info-area .single-info-box {
  /* background: #131227; */
  background-image: url(./images/video-bg.jpg);
  padding: 40px 25px 20px;
  border-radius: 7px;
  display: flex;
  background-position: left;
  margin-top: 10px;
}

.info-icon {
  display: inline-block;
  font-size: 35px;
  height: 80px;
  width: 80px;
  line-height: 80px;
  text-align: center;
  color: #5b03e4;
  background: #fff;
  border-radius: 50px;
  margin: 0 25px 0 0;
}

.contact-area .singles-contacts-box {
  background: #131227;
  padding: 30px 35px;
  border-radius: 10px;
  margin: 50px 0 30px;
  box-shadow: 10px 10px 10px #02041558;
}


.contact-area .singles-contacts-box .contact-title h3 {
  font-size: 38px;
  color: #fff;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0 0 26px;
}

.contact-area .form_box input,
.calculate {
  width: 100%;
  height: 54px;
  padding-left: 20px;
  padding-right: 20px;

  background: #131227;
  transition: .5s;
  border: 1px solid #202644;
  border-radius: 50px;
  color: #fff;

  margin-top: 20px;
}

.calculate {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.contact-area .form_box select {
  width: 60%;
  height: 54px;
  padding-left: 20px;
  background: #131227;
  transition: .5s;
  border: 1px solid #202644;
  color: #fff;
  margin: 0 5px;
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 50px;
}

.contact-area .form_box textarea {
  width: 100%;
  padding-left: 20px;
  padding-top: 15px;
  border: 1px solid #202644;
  height: 120px;
  outline: 0;
  transition: .5s;
  background: #131227;
  border-radius: 20px;
  color: #fff;
  margin-top: 20px;

}

.light-mode .contact-area .form_box textarea {
  background-color: transparent;
}

.form-button button {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  padding: 15px 38px;
  background: #5b03e4;
  border-radius: 30px;
  position: relative;
  z-index: 1;
  margin-top: 40px;
}

.form-button button::before {
  position: absolute;
  content: "";
  top: 0;
  left: -90px;
  height: 100%;
  width: 100%;
  background: url(images/btn-shape.png);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  z-index: -1;
  transition: .5s;
}

.form-button button:hover::before {
  opacity: 1;
  left: 0px;
}
.info-area .col-lg-4 .info-area-title , .info-area .col-lg-4 .info-area-title a{
  color: #fff;
}
/*************************************/
.Faq {
  padding: 50px 0;
}

.Faq img {
  width: 100%;
}

.Faq p {
  color: #000;
}

.light-mode .Faq p {
  color: #020415;
}

.faqs {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.detailsoff {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  border: 1px solid #fff;
  padding-left: 10px;
  border-radius: 1%;
  margin: 20px 0px;
  color: #707070;

}

.light-mode .detailsoff {
  border: 1px solid #020415;

  color: #020415;
}

.detailsoff p {
  margin-top: 10px;
  font-size: 18px;
}


.detailsoff button {
  background-color: transparent;
  border: none;
  border-left: 1px solid #fff;
  font-size: 20px;
  padding: 10px 15px;
  color: white;
}

.light-mode .detailsoff button {

  border-left: 1px solid #020415;

  color: #020415;
}


/****************************************/
.SigIn {
  padding-top: 120px;
}

/****************************************/

.style-four .feature-single-box {
  padding: 45px 40px 30px 40px;
  text-align: left;
  /* background: #141227; */
  background-image: url(./images/video-bg.jpg);
  border-radius: 10px;
  margin-bottom: 30px;
}

.style-four .feature-icon1 {
  float: left;
  margin-right: 30px;
  width: 95px;
  height: 95px;
  line-height: 95px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.style-four .feature-icon1 svg {
  color: #5b03e4;
  font-size: 40px;
}



.style-four .feature-title p {
  font-size: 16px;
  color: #919DA4;
  padding: 14px 0 0;
  font-weight: 400;
  text-align: left;
}

.style-four.feature-area .feature-button a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  transition: .5s;
  color: #6c5ce7;
  padding: 0 0 0 3px;
}

/****************************************/

.call-do-btn a {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: #fff;
  background: #5b03e4;
  padding: 13px 35px;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}

.call-do-action-text p {
  font-size: 16px;
  color: #919da4;
  font-weight: 400;
  margin: 0;
}

.googlea div {
  color: black !important;
}

.profile {
  padding-top: 200px;
}

.data-box-inner p span {
  text-align: center;
}

.change-thumb img {
  width: 50px;
  height: 50px;
}

.headerHome span {
  font-weight: 500;
}

.methodsslider img {
  width: 100px;
  object-fit: contain;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.methodsslider {
  text-align: center;
}

.infoHome thead {
  background-color: #5b03e4;
}

.finishedTask {
  padding: 20px 10px;
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.finishedTask p {
  font-size: 22px;
}

.finishedTask a {
  color: #5b03e4;
  margin-left: 10px;
  font-size: 22px;

}

/* Levels.css */
.levels-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.level-item {
  padding: 10px 20px;
  border: 1px solid #725aeb84;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.462);
  border-radius: 8px;
  transition: background-color 0.3s, box-shadow 0.3s;
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  width: 100%;
  align-items: flex-start;
  position: relative;
  text-align: center;
  padding-top: 30px;
}

body.dark-mode .level-item p {
  color: #000 !important;
}

.level-item .level-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: -50px;
  position: absolute;
  top: -80px;
  left: 40%;
  margin: auto;
  margin-bottom: 10px;
}

.level-item .level-title {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #333;
  text-align: center !important;
  margin: auto;
}

.level-item .level-description {
  font-size: 1.2em;
  color: #666;
}

.level-item.highlighted {
  background-color: #725aeb24;
  box-shadow: 10px 10px 10px #725aeb52;
  border: none;
}

.terms_content h2{
  text-align: center;
  margin-top: 100px;
  margin-bottom: 0px;
  color: #5b03e4;
  font-size: 42px;
}
.terms_content .section{
padding-top: 0px;
margin-top: 10px;
padding: 5px 40px;
}
.rtl .terms_content .section{

text-align: right;
direction: rtl;
}
.terms_content .section h3{
  color: #715AEB;
  font-size: 24px;
}

@media only screen and (max-width:600px) {
  .dnoned {
    display: none !important;
  }

  .contact-us .contact-us-content {
    padding: 0 !important;
  }

  .contact-us .contact-us-content #contact-form {
    padding: 10px 5px;
  }

  .main-banner form .col-lg-12 {
    margin-top: 0;
  }

  .main-banner form {
    min-height: unset;
  }

  .methodsslider img {
    width: 70px;
  }

  .navyy div {
    flex-direction: column;
  }

  footer {
    text-align: center;

  }

  .divicons {
    width: 100%;
  }

  .footerimg {
    width: 100px !important;
    margin-top: -120px;
    text-align: center;
  }

  footer h3 {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
  }

  footer p {
    font-size: 14px;
    /* text-align: center; */
    line-height: 15px;
  }

  .divmainsec2 {
    margin-left: 30px;
    margin-top: 100px;
  }

  .fslider .swiper-slide {
    background-position: center;
  }

  .slideh3 {
    right: 0px;
  }

  .aboutsec1 img {
    width: 70%;
  }

  .sec3 .slick-next,
  .sec3 .slick-prev,
  .sec3 .divbutton button {
    position: absolute;
    left: 80% !important;
  }

  .sec3 .swiper {
    margin-top: 200px;
  }

  body {
    overflow-x: hidden;
  }

  .cartLink span {
    width: 20px !important;
  }

  .slidessdetail button {
    width: 50px !important;
    font-size: 15px !important;
    padding: 2px !important;
  }

  .blogitemmm {
    flex-wrap: wrap;
  }

  .blogitemmm img {
    width: 100%;


  }

  .change-thumb img {
    width: 20px;
    height: 30px;
  }

  .infoHome .col-sm-3 {
    width: 80px;
  }

  .formcontacttt {
    height: auto;
    padding: 20px 0 !important;
    margin-top: 0;
  }

  .positiondiv {
    top: 20%;
    width: 90%;
    left: 3%;
  }

  .bloggg .slick-next,
  .bloggg .slick-prev {
    top: 75% !important;

  }

  .bloggg .slick-prev {
    left: 30% !important;
  }

  .positionslider {
    height: 60vh;
  }
  .fixed-buttons {
    bottom: 15px;
    display: flex;
    filter: drop-shadow(5px 5px 5px #00000024);
    flex-direction: column;
    gap: 10px;
    position: fixed;
    right: 24px;
}
  .payment {
    flex-direction: column;
  }

  .main-banner .caption {
    margin-top: -180px;
  }

  #second-form p {
    color: #000;
  }
}

.rtl .style-four .feature-title p {
  text-align: right;
}

body.dark-mode section h4,
body.dark-mode h2,
body.dark-mode section h2 {
  color: #fff;
}

table th,
table td {
  text-align: center;
}

table p {
  text-align: center;
}

body.dark-mode section p {
  color: #fff;
}

body.dark-mode .reviewssec3 p {
  color: #92a1b4;
}

body.dark-mode .main-banner p {
  color: #fff;
}

body.dark-mode table th p span,
table th p span {
  color: #fff;
}

body.dark-mode table th p {
  color: #fff;
}

body.dark-mode table td p {
  color: #000;
}

.invitioncard {
  background-color: white;
  border-radius: 20px;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.159);
}

body.dark-mode .aboutsec3 .col-md-2 p,
body.dark-mode .page-heading h4,
body.dark-mode .most-asked .accordion-head,
body.dark-mode .most-asked .accordions .content p,
body.dark-mode .blogitemmm h3,
body.dark-mode .thirddivblog h3,
body.dark-mode .SHOPALLL h3,
body.dark-mode .SHOPALLL p {
  color: #fff;

}

body.dark-mode .main-banner .section-heading h2 {
  color: #000;
}

body.dark-mode .feature-title p {
  color: #919da4;
}

body.dark-mode .aboutsec2 p {
  color: #000;
}

.rtl .services .service-item {
  text-align: right;
}

.rtl .services .section-heading h2 {
  padding-right: 0;
}

.rtl .divmainsec2>div {
  direction: rtl;
  text-align: right;
  padding-right: 50px;
}

body.dark-mode {
  background-color: #170c1c;

}

body.dark-mode .aboutsec3 .col-md-2 svg {
  color: #fff;
}

.rtl .projects .row {
  justify-content: flex-end;
}

.rtl .most-asked .row {
  direction: rtl;
}

.rtl .accordion-head {
  /* direction: ltr; */
  display: flex;
  justify-content: space-between;
}

.rtl #contact-form input,
.rtl #contact-form select,
.rtl #contact-form textarea,
.rtl #contact-form h5 {
  text-align: right;
}

.rtl #second-form input,
.rtl #second-form select,
.rtl #second-form textarea,
.rtl #second-form p {
  text-align: right;
}

.rtl .section-heading {
  direction: rtl;
}

.rtl .feature-area .sub-title h3::before {
  left: 102%;
}

body.dark-mode .happy-clients::before {
  background-color: #170c1c;

}

body.dark-mode .happy-clients::after {
  background-color: #170c1c;

}

body.dark-mode .services::before {
  background-color: #170c1c;

}

body.dark-mode .services::after {
  background-color: #170c1c;

}

body.dark-mode .contact-us::before {
  background-color: #170c1c;

}

body.dark-mode .contact-us::after {
  background-color: #170c1c;

}

body.dark-mode .happy-clients::after {
  background-color: #170c1c;

}

body.dark-mode .main-banner::after {
  background-color: #170c1c;

}

body.dark-mode .main-banner::before {
  background-color: #170c1c;

}

.thanks {
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SHOPALLL {
  margin-top: 100px;
}

.blogitemmm img {
  margin-top: 20px;
  height: 320px;
  object-fit: contain;
}

.bllogsdetail img {
  margin-top: 20px;
  height: 300px;
  object-fit: cover;
}

.rtl .bllogsdetail,
.rtl .blogitemmm {
  text-align: right;
  direction: rtl;
}

.bllogsdetail img {
  width: 100%;
  margin: 25px 0px;
}

.bloggg {
  position: relative;
}

.blogitemmm img {
  width: 100%;
}

.blogitemmm {
  margin: 20px 0px;
}

.blogitemmm h3 {

  font-size: 35px;
  color: black;
  margin-bottom: 10px;
  margin-left: 20px;

}

.blogitemmm p {
  color: #a1a1a1;
  margin-left: 20px;

}

.blogitemmm button {
  font-size: 14px;
  color: #fff;
  background-color: #c03afe;
  padding: 12px 50px;
  display: inline-block;
  border-radius: 25px;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  transition: all .3s;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  border: none;
}

.blogitemmm button:hover {
  background-color: white;
  color: black;
  border: 1px solid #dfb6ff;

}

/***********************************/
.searchhhh {
  border: none;
  outline: none;
  border-bottom: 1px solid #dfb6ff;
}

.thirddivblog {
  margin-top: 10px;

  text-align: center;
  padding: 20px;
}

.thirddivblog h3 {
  color: #000;
}

.thirddivblog img {
  width: 100%;
}

.thirddivh3 {
  font-size: 20px;
  position: relative;
}

.thirddivh3::before {
  content: '';
  height: 1px;
  width: 70px;
  background-color: #ddd3c2;
  position: absolute;
  top: 50%;
  left: 0%;

}

.thirddivh3::after {
  content: '';
  height: 1px;
  width: 70px;
  background-color: #ddd3c2;
  position: absolute;
  top: 50%;
  right: 0%;

}

.divforthird {
  display: flex;
  text-align: left;
  margin-top: 20px;
  border-bottom: 1px solid #ddd3c2;
  padding-bottom: 20px;
}

.divforthird img {
  width: 100px;
  height: 100px;
}

.divforthird h3 {
  font-size: 15px;
  margin-left: 10px;
  font-weight: bold !important;
  color: black;
}

.divforthird p {
  font-size: 10px;
  margin-left: 10px;
  color: #a1a1a1;
}

.fourthblog {
  margin-top: 10px;
  padding: 20px;
  text-align: center;
}

.fourthblog form {
  border: 1px solid #ddd3c2;
  padding: 50px 20px;
}

.fourthblog h3 {
  font-size: 22px;
}

.fourthblog input[type="email"] {
  width: 100%;
  margin: 5px 0px;
  text-align: center;
  outline: none;
  border: 1px solid #ddd3c2;
  padding: 5px;
}

.fourthblog input[type="submit"] {
  width: 100%;
  margin: 5px 0px;
  text-align: center;
  outline: none;
  border: none;
  padding: 5px;

  font-size: 20px;
  background-color: black;
  color: white;
  transition: all 1s ease-in-out;
}

.fourthblog input[type="submit"]:hover {
  background-color: white;
  color: black;
  border: 1px solid black;

}
/* 
.exchangeLogo{
  color: #5b03e4;
  font-size: 44px;
  text-align: center;
  margin-bottom: 30px;
  margin-top: -30px;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif ;
  font-weight: 700;
  font-style: normal;
} */




/* .exchangeLogo span {
  color: #c03afe;
} */

.contactt #contact-form input, #contact-form select{
  width: 95%;
}

 .rtl #second-form p{
padding-right: 0;
}

.rtl .dropdown-menu.show a{
  text-align: right;
}
.ticketDetaill{
  text-align: left ;
}
.rtl .ticketDetaill{
  text-align: right ;
}

.ticketDetaill  h3{
text-align: center;
font-weight: 700;
}
.ticketDetaill h5{
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 18px !important;
  color: #5b03e4 !important;
}
.ticketDetaill p{
  color: #000;
}

body.dark-mode .main-banner  .exchangeLogo{
  color: #c03afe;
}
body.dark-mode .main-banner  .exchangeLogo span{
  color: #fff ;
}